<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-0 pb-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <v-row>
          <v-col cols="10" class="py-0">
            <PageHeader :title="title" />
          </v-col>
        </v-row>
        <v-card class="mx-auto my-12 mt-2 mb-2" outlined max-width="1200">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="10"
                class="py-0"
                align="left"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="pt-0"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="showFilters = !showFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <v-row>
                  <!-- Numero afiliado-->
                  <v-col cols="12" md="3" class="py-0">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      clearable
                      v-mask="'###############'"
                      label="N° de afiliado"
                      v-model.trim="numeroAfiliado"
                      @change="buscarAfil"
                      autocomplete="off"
                    >
                      <template v-slot:append-outer>
                        <v-tooltip top max-width="50%">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                              @click="modalBusquedaAfiliado = true"
                            >
                              {{ searchIcon }}
                            </v-icon>
                          </template>
                          <span
                            >Buscar afiliado por nombre, apellido, DNI o
                            número</span
                          >
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <!-- Titular de grupo -->
                  <v-col cols="12" md="4" class="py-0">
                    <v-text-field
                      type="text"
                      outlined
                      clearable
                      dense
                      filled
                      disabled
                      label="Afiliado"
                      :loading="loadingBtn"
                      v-model.trim="afiliadoNombreApellido"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1" md="1" sm="1" class="py-0 pl-0 pt-2">
                    <v-tooltip top max-width="20%">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">
                          {{ infoIcon }}
                        </v-icon>
                      </template>
                      <span>
                        Para buscar afiliado presione en la lupa o busque por N°
                        de afiliado
                      </span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="12" md="2" sm="2" class="py-0 pl-0">
                    <v-text-field
                      dense
                      outlined
                      clearable
                      v-model="periodoVigenteAl"
                      label="Vigente al"
                      hint="Formato AAAAMM"
                      persistent-hint
                      type="text"
                      :rules="rules.periodoYyyyMm"
                      v-mask="'######'"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0" md="2" sm="2">
                    <v-autocomplete
                      v-model="optionBonifRecargoSelected"
                      :items="optioBonifRecargoItems"
                      label="Bonificación/Recargo"
                      return-object
                      :rules="rules.required"
                      item-text="value"
                      item-value="id"
                      outlined
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                    align="end"
                    class="text-right py-0 pt-5"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      color="primary"
                      elevation="2"
                      :disabled="!isFormValid"
                      small
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-data-table
          :headers="bonifiRecargoHeaders"
          :items="bonifiRecargoItems"
          class="elevation-1"
          :loading="isLoadingTable"
          show-expand
          :search="search"
          :expanded.sync="expanded"
          item-key="bonifRecargoId"
        >
          <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="handleExpansion(item, isExpanded)"
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  >{{
                    isExpanded ? "mdi-account-check" : "mdi-account"
                  }}</v-icon
                >
              </template>
              <span>Usuarios</span>
            </v-tooltip>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <strong>Fecha alta:</strong>
              {{ item.fechaAlta }}
              <strong style="padding-left: 80px">Usuario alta:</strong>
              {{ item.usuAlta }}
              <strong style="padding-left: 80px">Fecha modificación:</strong>
              {{ item.fechaModi }}
              <strong style="padding-left: 80px">Usuario modificación:</strong>
              {{ item.usuModi }}
            </td>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="6" class="">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col cols="6" class="text-right" align="end">
                  <v-btn
                    v-if="canImport"
                    color="primary"
                    small
                    style="text-transform: none"
                    @click="goToProcImportacion()"
                  >
                    Procesos de importación
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
        </v-data-table>
        <v-col cols="12" md="12">
          <v-btn
            :disabled="bonifiRecargoItems.length === 0"
            color="primary"
            outlined
            @click="exportExcel()"
            class="to-right fontsize mr-n4"
          >
            Exportar detalle
          </v-btn>
        </v-col>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-3 px-0"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-model="modalBusquedaAfiliado"
      v-if="modalBusquedaAfiliado"
      @keydown.esc="toggleModalBusquedaAfi"
      max-width="50%"
      persistent
    >
      <BusquedaAfiliado
        @toggleModalBusquedaAfi="toggleModalBusquedaAfi"
        @findAfiliado="findAfiliado"
      ></BusquedaAfiliado>
    </v-dialog>
  </v-container>
</template>

<script>
import helpersExcel from "@/utils/helpers/importExportExcel";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import FiltersSelected from "@/components/shared/FiltersSelected";
import GoBackBtn from "@/components/shared/GoBackBtn";
import PageHeader from "@/components/ui/PageHeader";
import Ayuda from "@/components/shared/Ayuda.vue";
import { mask } from "vue-the-mask";
import BusquedaAfiliado from "@/components/modules/afiliaciones/afiliados/BusquedaAfiliado.vue";

export default {
  name: "ConsultaBonifRecargo",
  components: {
    Ayuda,
    GoBackBtn,
    FiltersSelected,
    PageHeader,
    BusquedaAfiliado,
  },
  directives: { mask },
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode", //aparece como pagina visitada si ingreso por url
      this.optionCode
    );
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false,
    });
    this.setPermisos();
    this.periodoVigenteAl = this.getPeriodoActual();
    if (this.$store.state.filtersBonificacionesYRecargos != undefined)
      this.setFilters();
  },
  data: (vm) => ({
    routeToGo: "ImportesADevengar",
    rules: rules,
    title: "Consulta bonificación y recargos por grupo familiar",
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    calendarIcon: enums.icons.CALENDAR,
    chevronUpIcon: enums.icons.CHEVRON_UP,
    chevronDownIcon: enums.icons.CHEVRON_DOWN,
    searchIcon: enums.icons.SEARCH,
    infoIcon: enums.icons.SNB_INFO,
    canImport: false,
    periodoVigenteAl: "",
    optionCode: enums.webSiteOptions.CONSULTA_BONIFICACIONES_RECARGOS,
    loadingBtn: false,
    afiliado: null,
    optionBonifRecargoSelected: { id: null, value: "Todos" },
    filtersApplied: [],
    expanded: [],
    optioBonifRecargoItems: [
      { id: 1, value: "Bonificación" },
      { id: 2, value: "Recargo" },
      { id: null, value: "Todos" },
    ],
    bonifiRecargoItems: [],
    bonifiRecargoHeaders: [
      {
        text: "N° de grupo familiar",
        sortable: false,
        align: "left",
        value: "nroGrupo",
      },
      {
        text: "Afiliado titular",
        sortable: true,
        value: "afiliadoTitular",
      },
      {
        text: "Tipo",
        sortable: false,
        value: "tipoBonifRecargo",
      },
      {
        text: "Valor",
        sortable: false,
        align: "right",
        value: "porcentaje",
      },
      {
        text: "Vigencia desde",
        sortable: true,
        align: "left",
        value: "vigenciaDesde",
      },
      {
        text: "Vigencia hasta",
        sortable: true,
        align: "left",
        value: "vigenciaHasta",
      },
      {
        text: "Observaciones",
        sortable: false,
        align: "left",
        value: "observaciones",
      },
      {
        text: "N° de solicitud",
        sortable: false,
        align: "left",
        value: "nroSolicitud",
      },
      {
        text: "Regla comercial",
        sortable: false,
        align: "left",
        value: "reglaComercial",
      },
      { text: "", align: "end", value: "data-table-expand" },
    ],
    search: "",
    showExpand: false,
    isLoadingTable: false,
    showHelp: false,
    showFilters: true,
    isFormValid: false,
    afiliadoSelected: null,
    numeroAfiliado: null,
    afiliadoNombreApellido: null,
    codigoAfiliado: null,
    modalBusquedaAfiliado: false,
    nroGrupo: null,
    allowedActions: null,
    saveFilters: {},
  }),
  beforeRouteLeave(to, from, next) {
    // Valido que si no es el componente ProcesosImportacionBonifRecargo borre los filtros
    if (to.path.includes("/procesos-importacion-bonificaciones-recargos"))
      next();
    else {
      this.$store.state.filtersBonificacionesYRecargos = null;
      next();
    }
  },
  methods: {
    ...mapActions({
      getAfiliadosByNumero: "afiliaciones/getAfiliadosByNumero",
      getBonificacionesYRecargos: "devengamientos/getBonificacionesYRecargos",
      fetchAfiliadosByParams: "agenteCuenta/fetchAfiliadosByParams",
      setAlert: "user/setAlert",
    }),
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enums.webSiteOptions.IMPORTACION_BONIFICACIONES_RECARGOS:
            this.canImport = true;
            break;
          default:
            break;
        }
      });
    },
    setFilters() {
      this.nroGrupo = this.$store.state.filtersBonificacionesYRecargos.benGrId;
      this.periodoVigenteAl =
        this.$store.state.filtersBonificacionesYRecargos.periodoVigenciaAl;
      this.optionBonifRecargoSelected.id =
        this.$store.state.filtersBonificacionesYRecargos.bonifRecargo;
      this.afiliadoNombreApellido =
        this.$store.state.filtersBonificacionesYRecargos.afiliadoNomApe;
      this.numeroAfiliado =
        this.$store.state.filtersBonificacionesYRecargos.numeroAfiliado;
      this.applyFilters();
    },
    findAfiliado(afiliadoSelected) {
      this.nroGrupo = afiliadoSelected.nroGrupo;
      this.afiliadoNombreApellido = `${afiliadoSelected.afiliadoApe} ${afiliadoSelected.afiliadoNom}`;
      this.numeroAfiliado = afiliadoSelected.numero;
    },
    async applyFilters() {
      this.showFilters = false;
      this.customizeFiltersApplied();
      this.isLoadingTable = true;
      const data = {
        benGrId: this.nroGrupo,
        periodoVigenciaAl: this.periodoVigenteAl,
        bonifRecargo: this.optionBonifRecargoSelected.id,
      };
      this.saveFilters = {
        benGrId: this.nroGrupo,
        periodoVigenciaAl: this.periodoVigenteAl,
        bonifRecargo: this.optionBonifRecargoSelected.id,
        afiliadoNomApe: this.afiliadoNombreApellido,
        numeroAfiliado: this.numeroAfiliado,
      };
      try {
        const res = await this.getBonificacionesYRecargos(data);
        if (res) this.bonifiRecargoItems = res;
      } catch (err) {}
      this.isLoadingTable = false;
    },
    async buscarAfil() {
      if (this.numeroAfiliado != null && this.numeroAfiliado != "") {
        try {
          this.loadingBtn = true;
          const afiliadosResponse = await this.fetchAfiliadosByParams({
            numero: this.numeroAfiliado,
          });
          this.loadingBtn = false;
          this.afiliadoNombreApellido = `${afiliadosResponse[0].afiliadoApe} ${afiliadosResponse[0].afiliadoNom}`;
          this.numeroAfiliado = afiliadosResponse[0].numero;
          this.nroGrupo = afiliadosResponse[0].nroGrupo;
        } catch (error) {
          this.loadingBtn = false;
          this.afiliadoNombreApellido = null;
        }
        if (!this.afiliadoNombreApellido) {
          this.setAlert({
            type: "warning",
            message: "N° de afiliado inexistente",
          });
          this.numeroAfiliado = null;
        }
      } else {
        this.afiliadoNombreApellido = null;
        this.numeroAfiliado = null;
        this.nroGrupo = null;
      }
    },
    exportExcel() {
      let result = [];
      this.bonifiRecargoItems?.forEach((x) =>
        result.push({
          ["Afiliado"]: x.afiliadoTitular?.toString(),
          ["Tipo documento"]: x.tipoDoc?.toString(),
          ["Numero documento"]: x.nroDoc?.toString(),
          ["Numero grupo"]: x.nroGrupo?.toString(),
          ["Tipo"]: x.tipoBonifRecargo?.toString(),
          ["Porcentaje"]: x.porcentaje?.toString(),
          ["Vigencia desde"]: x.vigenciaDesde?.toString(),
          ["Vigencia hasta"]: x.vigenciaHasta?.toString(),
          ["Observaciones"]: x.observaciones?.toString(),
        })
      );

      let formato = {
        filas: result,
        hoja: "Resultado",
      };
      helpersExcel.excelExport(formato, "Bonificaciones y recargos");
    },
    goToProcImportacion() {
      this.$store.state.filtersBonificacionesYRecargos = this.saveFilters;
      this.$router.push({
        name: "ProcesosImportacionBonifRecargo",
      });
    },
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.afiliadoNombreApellido) {
        this.filtersApplied.splice(4, 1, {
          key: "afiliadoNombreApellido",
          label: "Afiliado",
          model: this.afiliadoNombreApellido,
        });
      }
      if (this.periodoVigenteAl) {
        this.filtersApplied.splice(1, 1, {
          key: "periodoVigenteAl",
          label: "Vigente al",
          model: this.periodoVigenteAl,
        });
      }
      if (this.optionBonifRecargoSelected) {
        this.filtersApplied.splice(2, 1, {
          key: "optionBonifRecargoSelected",
          label: "Tipo",
          model: this.optionBonifRecargoSelected.value,
        });
      }
    },
    getPeriodoActual() {
      let now = new Date();
      let month = ("0" + (now.getMonth() + 1)).slice(-2);
      let periodoNow = now.getFullYear() + month;

      return periodoNow;
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    toggleModalBusquedaAfi() {
      this.modalBusquedaAfiliado = !this.modalBusquedaAfiliado;
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
  },
};
</script>